<template>
  <div :class="{ spaceComments: !isMobile, spaceCommentsMobile: isMobile }">
    <div :class="{ gridListMenu: !isMobile, gridListMenuMobile: isMobile }">
        <div
          class="menu d-flex align-items-center"
          @click="getList('')"
          :class="{ activeList: selectedList === '' }"
        >
          <IconSax :primary="selectedList === ''" name="direct-inbox" size="1.2rem" class="mr-1" />
          ({{ CountComments.todos }}) {{ $t("comments.all") }}
        </div>
        <div
          class="menu d-flex align-items-center"
          @click="getList('draft')"
          :class="{ activeList: selectedList === 'draft' }"
        >
          <IconSax :primary="selectedList === 'draft'" name="clock" size="1.2rem" class="mr-1" />
          ({{ CountComments.pendentes }}) {{ $t("comments.pending") }}
        </div>
        <div
          class="menu d-flex align-items-center"
          @click="getList('published')"
          :class="{ activeList: selectedList === 'published' }"
        >
          <IconSax :primary="selectedList === 'published'" name="like-1" size="1.2rem" class="mr-2" />
          ({{ CountComments.aprovados }}) {{ $t("comments.approved") }}
        </div>
        <div
          class="menu d-flex align-items-center"
          @click="getList('reproved')"
          :class="{ activeList: selectedList === 'reproved' }"
        >
          <IconSax :primary="selectedList === 'reproved'" name="dislike" size="1.2rem" class="mr-2" />
          ({{ CountComments.reprovados }}) {{ $t("comments.disapproved") }}
        </div>
        <div
          class="menu d-flex align-items-center"
          @click="getList('trashed')"
          :class="{ activeList: selectedList === 'trashed' }"
        >
          <IconSax :primary="selectedList === 'trashed'" name="trash" size="1.2rem" class="mr-2" />
          ({{ CountComments.lixeira }}) {{ $t("comments.trash") }}
        </div>
      </div>
      <div
        class="animationOpacity"
        :class="{ spaceComunidade: !isMobile, spaceComunidadeMobile: isMobile }"
      >
      <div class="headerFlex">
        <div class="title">
          <TitlePage :title="$t('comments.title')"  >
            <IconSax name="messages-2" />
          </TitlePage>
          
        </div>
      </div>
      <div class="spaceSearch">
        <div class="inputSearch">
          <IconSax
            name="search-normal"
            size="1rem"
            @click="getAllComments()"
            class="Pointer search"
          />
          <b-form-input
            class="input-busca"
            @change="getAllComments()"
            v-model="searchComment"
            :placeholder="$t('comments.placeholder')"
          ></b-form-input>
        </div>
      </div>
      <div v-if="btnsActions" class="animations-btn-action">
        <div class="flex">
          <button
            class="btns-actions animations-btn-action"
            v-on:click="actionEditar('aprovar')"
          >
            <svg
              class="espacamento-svg-action"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7022 0.289387C12.6162 0.197689 12.5139 0.124912 12.4012 0.0752425C12.2885 0.0255733 12.1676 0 12.0455 0C11.9234 0 11.8025 0.0255733 11.6897 0.0752425C11.577 0.124912 11.4747 0.197689 11.3887 0.289387L4.49736 7.58766L1.60206 4.51574C1.51277 4.42447 1.40738 4.35275 1.29188 4.30462C1.17639 4.25649 1.05306 4.23291 0.92894 4.23516C0.804822 4.23741 0.68234 4.26559 0.568489 4.31793C0.454648 4.37017 0.351656 4.44579 0.265408 4.5402C0.179159 4.63461 0.111347 4.74604 0.0658268 4.86823C0.020316 4.99042 -0.00200475 5.12083 0.000141283 5.25212C0.00228732 5.38331 0.0288629 5.51294 0.078342 5.63328C0.12783 5.75371 0.199251 5.86259 0.288533 5.95387L3.84059 9.71061C3.92658 9.80228 4.0289 9.87507 4.14161 9.92477C4.25434 9.97437 4.37524 10 4.49736 10C4.61947 10 4.74035 9.97437 4.85311 9.92477C4.96577 9.87507 5.06808 9.80228 5.15411 9.71061L12.7022 1.72752C12.7961 1.63591 12.8711 1.52472 12.9223 1.40096C12.9735 1.27721 13 1.14356 13 1.00845C13 0.873345 12.9735 0.739707 12.9223 0.615949C12.8711 0.492192 12.7961 0.381006 12.7022 0.289387Z"
                fill="var(--maincolor)"
              />
            </svg>
            {{ $t("comments.to_approve") }}
          </button>
          <button
            class="btns-actions animations-btn-action"
            v-on:click="actionEditar('reprovar')"
          >
            <svg
              class="espacamento-svg-action"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.17385 4.99927L9.75374 1.42724C9.9105 1.27045 9.99859 1.05779 9.99859 0.836055C9.99859 0.614321 9.9105 0.40167 9.75374 0.244874C9.59697 0.0880859 9.38434 0 9.16264 0C8.94094 0 8.72831 0.0880859 8.57154 0.244874L4.99998 3.82524L1.42846 0.244874C1.27169 0.0880859 1.05906 0 0.83736 0C0.615657 0 0.403028 0.0880859 0.246263 0.244874C0.0894969 0.40167 0.00142358 0.614321 0.00142358 0.836055C0.00142358 1.05779 0.0894969 1.27045 0.246263 1.42724L3.82611 4.99927L0.246263 8.57134C0.168229 8.64878 0.106298 8.74087 0.06403 8.84237C0.0217624 8.94378 0 9.05261 0 9.16252C0 9.27252 0.0217624 9.38134 0.06403 9.48276C0.106298 9.58426 0.168229 9.67635 0.246263 9.7537C0.323655 9.83181 0.415733 9.89375 0.517185 9.93597C0.618637 9.97827 0.727457 10 0.83736 10C0.947262 10 1.05608 9.97827 1.15754 9.93597C1.25899 9.89375 1.35107 9.83181 1.42846 9.7537L4.99998 6.17331L8.57154 9.7537C8.64897 9.83181 8.74105 9.89375 8.84253 9.93597C8.94394 9.97827 9.05275 10 9.16264 10C9.27262 10 9.38143 9.97827 9.48283 9.93597C9.58432 9.89375 9.6764 9.83181 9.75374 9.7537C9.83183 9.67635 9.89377 9.58426 9.93598 9.48276C9.97827 9.38134 10 9.27252 10 9.16252C10 9.05261 9.97827 8.94378 9.93598 8.84237C9.89377 8.74087 9.83183 8.64878 9.75374 8.57134L6.17385 4.99927Z"
                fill="var(--maincolor)"
              />
            </svg>
            {{ $t("comments.to_disapprove") }}
          </button>
        </div>
        <div class="textTotalSelected">
          {{ $t("comments.total_selected") }} {{ status_auto.length }}
        </div>
      </div>
      <div class="spaceTable animationOpacity2">
        <b-table
          :fields="fieldsx"
          show-empty
          small
          stacked="md"
          :items="AllComments"
          :tbody-tr-class="rowClass"
          :filter-included-fields="filterOn"
          v-if="!isMobile"
        >
          <template #empty>
            <p class="title text-center-empty">{{ emptyText }}</p>
          </template>
          <template #emptyfiltered>
            <p class="title text-center-empty">{{ emptyText }}</p>
          </template>

          <template v-slot:head(id)>
            <label class="check" v-if="!isMobile">
              <b-form-checkbox-group
                id="checkbox-group-all"
                v-model="status_auto2"
                @change="clickCheckAll"
                class="input-check"
                name="flavour-all"
              >
                <b-form-checkbox value="xx"></b-form-checkbox>
              </b-form-checkbox-group>
            </label>
          </template>

          <template #cell(id)="row">
            <div v-if="!isMobile">
              <b-form-checkbox-group
                :id="`checkbox-group-` + row.item.id"
                v-model="status_auto"
                @change="clickCheck"
                class="input-check"
                :name="`flavour-` + row.item.id"
              >
                <b-form-checkbox :value="row.item.id"></b-form-checkbox>
              </b-form-checkbox-group>
            </div>
          </template>

          <template #cell(member)="row">
            <div class="spaceCreate animationOpacity2">
              <div class="dataCreate">
                <div class="userPic">
                  <img
                    v-if="
                      row.item.member.picture === null ||
                        row.item.member.picture === undefined ||
                        row.item.member.picture === ''
                    "
                    src="@/assets/icons/pic-profile.svg"
                  />
                  <img v-else :src="row.item.member.picture" />
                </div>
                <div class="userDados">
                  <div class="name">{{ row.item.member.name }}</div>
                  <div class="email">{{ row.item.member.email }}</div>
                </div>
              </div>
              <div class="date">
                {{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}
              </div>
            </div>
          </template>

          <template #cell(comment)="row">
            <div class="spaceComment animationOpacity2">

              <div class="comment" v-if="row.item.comment.length>270 && !readMore[row.item.id]">{{ row.item.comment.substring(0, 270) }} ... <a @click="showMore(row.item.id)" href="#">ver mais</a></div>
              <div class="comment" v-else-if="row.item.comment.length>270 && readMore[row.item.id]">{{ row.item.comment }} <a @click="showLess(row.item.id)" href="#">ver menos</a></div>
              <div class="comment" v-else>{{ row.item.comment }}</div>

              <div class="actions">
                <button
                  class="btns resp"
                  v-if="selectedList === 'published' && row.item.liked === false"
                  @click="likeComent(row.item)"
                >
                  <span class="numberLiked">{{ row.item.likes_count }}</span
                  >Curtir
                </button>
                <button
                  class="btns resp"
                  v-if="selectedList === 'published' && row.item.liked === true"
                  @click="removeLikeComent(row.item)"
                >
                  <span class="numberLiked">{{ row.item.likes_count }}</span
                  >Descurtir
                </button>
                <button
                  class="btns aprove"
                  v-if="
                    (row.item.status === 'draft' ||
                      row.item.status === 'reproved') &&
                      selectedList !== 'trashed'
                  "
                  @click="aprovComment(row.item.id)"
                >
                {{ $t("comments.to_approve") }}
                </button>
                <button
                  class="btns removeaprove"
                  v-if="
                    row.item.status === 'publish' && selectedList !== 'trashed'
                  "
                  @click="removeAprovComment(row.item.id)"
                >
                {{ $t("comments.remove_approval") }}
                </button>
                <button
                  class="btns resp"
                  v-if="
                    selectedList !== 'trashed' && row.item.status !== 'reproved'
                  "
                  @click="respComment(row.item.id), (row.item.toEdit = true)"
                >
                {{ $t("comments.to_respond") }}
                </button>
                <button
                  class="btns delete"
                  v-if="
                    row.item.status !== 'reproved' && selectedList !== 'trashed'
                  "
                  @click="ReprovComment(row.item.id)"
                >
                {{ $t("comments.to_disapprove") }}
                </button>
                <button
                  class="btns delete"
                  v-if="
                    row.item.status === 'reproved' && selectedList !== 'trashed'
                  "
                  @click="deleteComment(row.item.id)"
                >
                {{ $t("comments.to_delete") }}
                </button>
                <button
                  class="btns delete"
                  v-if="selectedList === 'trashed'"
                  @click="restauraComment(row.item.id)"
                >
                {{ $t("comments.to_restore") }}
                </button>
              </div>
              <div v-if="row.item.toEdit">
                <textarea
                  type="text"
                  id="myComment"
                  class="animationOpacity"
                  placeholder="Escreva sua resposta"
                  v-model="minhaResposta"
                ></textarea>
                <button
                  class="btnResp cancelaBtn animationOpacity2"
                  @click.prevent="row.item.toEdit = false"
                >
                {{ $t("comments.to_cancel") }}
                </button>
                <button
                  class="btnResp animationOpacity2"
                  @click.prevent="responderComentario(row.item)"
                >
                {{ $t("comments.to_respond") }}
                </button>
              </div>
            </div>

            <!-- replies -->
            <template v-for="(node, index) in row.item.replies">
              <div class="spaceComment spaceCreate mt-5 animationOpacity2">
                <div class="dataCreate">
                  <div class="userPic">
                    <img
                      v-if="
                        node.member.picture === null ||
                          node.member.picture === undefined ||
                          node.member.picture === ''
                      "
                      src="@/assets/icons/pic-profile.svg"
                    />
                    <img v-else :src="node.member.picture" />
                  </div>
                  <div class="userDados">
                    <div class="name">{{ node.member.name }}</div>
                    <div class="email">{{ node.member.email }}</div>
                  </div>
                </div>

                <div class="comment" v-if="node.comment.length>270 && !readMore[node.id]">{{ node.comment.substring(0, 270) }} ... <a @click="showMore(node.id)" href="#">ver mais</a></div>
                <div class="comment" v-else-if="node.comment.length>270 && readMore[node.id]">{{ node.comment }} <a @click="showLess(node.id)" href="#">ver menos</a></div>
                <div class="comment" v-else>{{ node.comment }}</div>

                <div class="date dateResp">
                  {{ node.created_at | moment("DD/MM/YYYY HH:mm") }}
                </div>

                <div class="actions">
                  <button
                    class="btns delete"
                    @click="deleteComment(node.id)"
                  >
                  {{ $t("comments.to_delete") }}
                  </button>

                </div>

              </div>
            </template>
          </template>

          <template #cell(lesson)="row">
            <div
              class="spaceInfo animationOpacity2"
              @click="moveToLesson(row.item)"
            >
              <div class="info">{{ row.item.lesson.title }}</div>
              <div class="info">{{ row.item.lesson.module.title }}</div>
              <div class="info">{{ row.item.lesson.module.course.title }}</div>
            </div>
          </template>
        </b-table>

        <b-table
          :fields="fieldsxMobile"
          show-empty
          small
          :items="AllComments"
          :tbody-tr-class="rowClass"
          :filter-included-fields="filterOn"
          v-if="isMobile"
        >
          <template #empty>
            <p class="title text-center-empty">{{ emptyText }}</p>
          </template>
          <template #emptyfiltered>
            <p class="title text-center-empty">{{ emptyText }}</p>
          </template>

          <template #cell(member)="row">
            <div class="spaceCreate animationOpacity2">
              <div class="dataCreate">
                <div class="userDados">
                  <div class="name">{{ row.item.member.name }}</div>
                  <div class="email">
                    {{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template #cell(comment)="row">
            <div class="spaceComment animationOpacity2">
              <div class="comment">{{ row.item.comment }}</div>
            </div>
          </template>

          <template #cell(lesson)="row">
            <div class="spaceComment animationOpacity2">
              <div class="actions">
                <button
                  class="btns resp"
                  v-if="selectedList === 'published' && row.item.liked === false"
                  @click="likeComent(row.item)"
                >
                  <span class="numberLiked">{{ row.item.likes_count }}</span
                  >
                  {{ $t("comments.to_like") }}
                </button>
                <button
                  class="btns resp"
                  v-if="selectedList === 'published' && row.item.liked === true"
                  @click="removeLikeComent(row.item)"
                >
                  <span class="numberLiked">{{ row.item.likes_count }}</span
                  >
                  {{ $t("comments.to_dislike") }}
                </button>
                <button
                  class="btns aprove"
                  v-if="
                    (row.item.status === 'draft' ||
                      row.item.status === 'reproved') &&
                      selectedList !== 'trashed'
                  "
                  @click="aprovComment(row.item.id)"
                >
                {{ $t("comments.to_approve") }}
                </button>
                <button
                  class="btns removeaprove"
                  v-if="
                    row.item.status === 'publish' && selectedList !== 'trashed'
                  "
                  @click="removeAprovComment(row.item.id)"
                >
                {{ $t("comments.remove_approval") }}
                </button>
                <button
                  class="btns resp"
                  v-if="
                    selectedList !== 'trashed' && row.item.status !== 'reproved'
                  "
                  @click="moveToLesson(row.item)"
                >
                {{ $t("comments.to_respond") }}
                </button>
                <button
                  class="btns delete"
                  v-if="
                    row.item.status !== 'reproved' && selectedList !== 'trashed'
                  "
                  @click="ReprovComment(row.item.id)"
                >
                {{ $t("comments.to_disapprove") }}
                </button>
                <button
                  class="btns delete"
                  v-if="
                    row.item.status === 'reproved' && selectedList !== 'trashed'
                  "
                  @click="deleteComment(row.item.id)"
                >
                {{ $t("comments.to_delete") }}
                </button>
                <button
                  class="btns delete"
                  v-if="selectedList === 'trashed'"
                  @click="restauraComment(row.item.id)"
                >
                {{ $t("comments.to_restore") }}
                </button>
              </div>
              <div v-if="row.item.toEdit">
                <textarea
                  type="text"
                  id="myComment"
                  class="animationOpacity"
                  placeholder="Escreva sua resposta"
                  v-model="minhaResposta"
                ></textarea>
                <button
                  class="btnResp cancelaBtn animationOpacity2"
                  @click.prevent="row.item.toEdit = false"
                >
                {{ $t("comments.to_cancel") }}
                </button>
                <button
                  class="btnResp animationOpacity2"
                  @click.prevent="responderComentario(row.item)"
                >
                {{ $t("comments.to_respond") }}
                </button>
              </div>
            </div>
          </template>
        </b-table>

        <div class="paginate">
          <div class="text" @click="toPage('prev')">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 6H1M1 6L6 11M1 6L6 1"
                stroke="#333333"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ $t("comments.prev") }}
          </div>
          <div class="text" @click="toPage('next')">
            {{ $t("comments.next") }}
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 6L11 6M11 6L6 1M11 6L6 11"
                stroke="#333333"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CommentService from "@/services/resources/CommentService";
const serviceComments = CommentService.build();
import CourseService from "@/services/resources/CourseService";
const serviceCource = CourseService.build();
import TitlePage from "@/components/common/TitlePage.vue";

export default {
  components: {
    TitlePage
  },
  data() {
    return {
      moderation: true,
      rowClass: "row-table-body",
      emptyText: this.$t('comments.txt6'),
      filterOn: [],
      AllComments: [],
      replies: [],
      fieldsx: [
        { key: "id", label: "" },
        { key: "member", label: this.$t('comments.txt1') },
        { key: "comment", label: this.$t('comments.txt2') },
        { key: "lesson", label: this.$t('comments.txt3') },
      ],
      fieldsxMobile: [
        { key: "member", label: this.$t('comments.txt4') },
        { key: "comment", label: this.$t('comments.txt2') },
        { key: "lesson", label: this.$t('comments.txt5') },
      ],
      pageActual: 1,
      lastPage: 1,
      firstPage: 1,
      openRespId: "",
      minhaResposta: "",
      textAreaResp: "",
      selectedList: "draft",
      searchComment: "",
      status_auto: [],
      status_auto2: [],
      btnsActions: false,
      btnsActionsEditar: true,
      CountComments: [],
      client: {
        width: 0,
      },
      readMore: {},
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    showMore(id) {
      this.$set(this.readMore, id, true);
    },
    showLess(id) {
      this.$set(this.readMore, id, false);
    },
    removeLikeComent(dados) {
      var data = {
        id:
          dados.lesson.module.course.id +
          "/module/" +
          dados.lesson.module.id +
          "/lesson/" +
          dados.lesson.id +
          "/comment/" +
          dados.id +
          "/like",
      };
      this.$root.$emit("loadOn");
      serviceCource
        .destroy(data)
        .then((resp) => {
          this.getAllComments();
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
    },
    likeComent(dados) {
      var data = {
        id:
          dados.lesson.module.course.id +
          "/module/" +
          dados.lesson.module.id +
          "/lesson/" +
          dados.lesson.id +
          "/comment/" +
          dados.id +
          "/like",
      };
      this.$root.$emit("loadOn");
      serviceCource
        .updateId(data)
        .then((resp) => {
          this.getAllComments();
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    actionEditar(action) {
      let itens = this.status_auto;
      var data = "";
      if (action === "reprovar") {
        for (let i = 0; i < itens.length; i++) {
          const element = itens[i];
          data = { id: element, status: "reproved" };
          this.$root.$emit("loadOn");
          serviceComments
            .postID(data)
            .then((resp) => {
              this.$root.$emit("loadOff");
            })
            .catch((err) => {
              this.$root.$emit("loadOff");
            });
        }
        this.getAllComments();
      } else {
        for (let i = 0; i < itens.length; i++) {
          const element = itens[i];
          data = { id: element, status: "published" };
          this.$root.$emit("loadOn");
          serviceComments
            .postID(data)
            .then((resp) => {
              this.$root.$emit("loadOff");
            })
            .catch((err) => {
              this.$root.$emit("loadOff");
            });
        }
        this.getAllComments();
      }
    },
    clickCheck() {
      this.verificaBtn();
    },
    clickCheckAll() {
      if (this.status_auto.length === 0) {
        let coments = this.AllComments;
        let array = [];
        for (let i = 0; i < coments.length; i++) {
          const element = coments[i].id;
          array.push(element);
        }
        this.status_auto = array;
        this.verificaBtn();
      } else {
        this.status_auto = [];
        this.status_auto2 = [];
        this.verificaBtn();
      }
    },
    verificaBtn() {
      if (this.status_auto.length !== 0) {
        this.btnsActions = true;
      } else if (this.status_auto.length === 0) {
        this.btnsActions = false;
      }
      this.verificaBtn2();
    },
    verificaBtn2() {
      if (this.status_auto.length === 1) {
        this.btnsActionsEditar = true;
      } else if (this.status_auto.length !== 1) {
        this.btnsActionsEditar = false;
      }
    },
    getList(data) {
      this.selectedList = data;
      if (data === "trashed") {
        this.getTrashed();
      } else {
        this.getAllComments();
      }
    },
    getTrashed() {
      this.$root.$emit("loadOn");
      this.pageActual = 1;
      var url = "trash?page=" + this.pageActual;
      serviceComments
        .read(url)
        .then((resp) => {
          var data = resp.data;
          for (let i = 0; i < data.length; i++) {
            data[i].toEdit = false;
          }
          this.pageActual = resp.current_page;
          this.lastPage = resp.last_page;
          this.AllComments = data;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
    },
    toPage(action) {
      if (action === "prev") {
        if (this.pageActual !== 1) {
          this.pageActual = this.pageActual - 1;
        }
      } else {
        if (this.lastPage > this.pageActual) {
          this.pageActual = this.pageActual + 1;
        }
      }
      this.getAllComments();
    },
    responderComentario(row) {
      var data = {
        id: `/${row.lesson.module.course.id}/module/${row.lesson.module.id}/lesson/${row.lesson.id}/comment`,
        comment: this.minhaResposta,
        parent_lesson_comment_id: row.id,
      };
      this.$root.$emit("loadOn");
      serviceCource
        .postID(data)
        .then((resp) => {
          this.minhaResposta = "";
          this.getAllComments();
          this.$root.$emit("loadOff");

          this.$bvToast.toast(this.$t('settings.txt83'), {
            title: this.$t('settings.txt84'),
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
    },
    respComment(id) {
      this.openRespId = id;
      this.textAreaResp = true;
    },
    restauraComment(idComment) {
      this.$root.$emit("loadOn");
      var data = { id: idComment + "/restore" };
      serviceComments
        .postID(data)
        .then((resp) => {
          this.$root.$emit("loadOff");
          this.getTrashed();
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
    },
    deleteComment(idComment) {
      this.$root.$emit("loadOn");
      serviceComments
        .destroy(idComment)
        .then((resp) => {
          this.$root.$emit("loadOff");
          this.getAllComments();
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
    },
    moveToLesson(row) {
      let routeData = this.$router.resolve(
        "/curso/" +
          row.lesson.module.course.id +
          "/modulo/" +
          row.lesson.module.id +
          "/aula/" +
          row.lesson.id +
          ""
      );
      window.open(routeData.href, "_blank");
    },
    removeAprovComment(idComment) {
      this.$root.$emit("loadOn");
      serviceComments
        .postID({ id: idComment, status: "draft" })
        .then((resp) => {
          this.$root.$emit("loadOff");
          this.getAllComments();
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
    },
    ReprovComment(idComment) {
      this.$root.$emit("loadOn");
      serviceComments
        .postID({ id: idComment, status: "reproved" })
        .then((resp) => {
          this.$root.$emit("loadOff");
          this.getAllComments();
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
    },
    aprovComment(idComment) {
      this.$root.$emit("loadOn");
      serviceComments
        .postID({ id: idComment, status: "published" })
        .then((resp) => {
          this.$root.$emit("loadOff");
          this.getAllComments();
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
    },
    getCountComments() {
      serviceComments
        .read("categories")
        .then((resp) => {
          this.CountComments = resp;
        })
        .catch((err) => {
        });
    },
    getAllComments() {
      this.$root.$emit("loadOn");
      this.status_auto = [];
      this.status_auto2 = [];
      this.btnsActions = false;
      this.btnsActionsEditar = true;

      if (this.pageActual === null) {
        this.pageActual = 1;
      }

      var url = "";
      if (this.searchComment === "" || this.searchComment === null) {
        if (this.selectedList === "") {
          url = "page=" + this.pageActual;
        } else {
          url = "page=" + this.pageActual + "&status[]=" + this.selectedList;
        }
      } else {
        if (this.selectedList === "") {
          url =
            "page=" + this.pageActual + "&search_string=" + this.searchComment;
        } else {
          url =
            "page=" +
            this.pageActual +
            "&status[]=" +
            this.selectedList +
            "&search_string=" +
            this.searchComment;
        }
      }
      serviceComments
        .search(url)
        .then((resp) => {
          var data = resp.data;
          for (let i = 0; i < data.length; i++) {
            data[i].toEdit = false;
          }
          this.pageActual = resp.current_page;
          this.lastPage = resp.last_page;
          this.AllComments = data;
          this.replies = data.replies
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
        });
      this.getCountComments();
    },
  },
  mounted() {
    this.getAllComments();
  },
};
</script>

<style lang="scss">
.spaceComunidadeMobile {
  width: 100%;
  padding: 0 20px 0px 20px;
  padding-top: 70px;
  .table tr.row-table-body {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .textTotalSelected {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #81858e;
    margin-bottom: -40px;
    margin-top: 60px;
  }
  .espacamento-svg-action {
    margin-right: 5px;
    margin-bottom: 3px;
  }
  .btns-actions {
    border: none;
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    border-radius: 3px;
    height: 38px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: var(--maincolor);
    margin-right: 20px;
    background-color: #fff0;
    width: 136px;
    margin-top: 30px;
    margin-bottom: -40px;
  }
  .spaceSearch {
    .inputSearch {
      position: relative;
      margin-top: 20px;
      img {
        cursor: pointer;
      }
    }
    .input-busca {
      width: 100%;
      height: 60px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      border-radius: 30px;
      padding: 20px 30px;
      font-family: Inter;
      font-weight: normal;
      color: #81858e;
      font-size: 14px;
      transition: 0.3s;
    }
    .input-busca:hover,
    .input-busca:focus {
      box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
    }
    .search {
      position: absolute;
      right: 25px;
      top: 24px;
      width: 14.7px;
      height: 14.7px;
      z-index: 999;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
  }
  .activeList {
    color: var(--maincolor) !important;
    transition: 0.3s;
    background: #f9f9f92b;
    border-radius: 3px;
    border: 1px solid var(--maincolor);
    svg {
      path {
        stroke: var(--maincolor) !important;
      }
    }
  }
  .paginate {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 60px;
    margin-top: 40px;
    .text {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #333333;
      cursor: pointer;
    }
    .text:hover {
      color: #002363;
    }
    .text:hover svg path {
      stroke: #002363 !important;
    }
  }
  .cancelaBtn {
    margin-right: 20px;
    background: #f7f7f7 !important;
    color: #81858e !important;
  }
  .btnResp {
    background: var(--maincolortrans);
    color: var(--maincolor);
    font-weight: 600;
    font-size: 13px;
    border: none;
    border-radius: 3px;
    padding: 12px 25px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  textarea {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 0.5px solid #ededf0;
    padding: 5px 0;
    padding-right: 20px;
    font-size: 13px;
    min-height: 50px;
    margin-top: 20px;
  }
  .hide {
    display: none;
  }
  .spaceTable {
    margin-top: 60px;
    .spaceInfo {
      cursor: pointer;
      .info {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #81858e;
        margin-bottom: 5px;
      }
    }
    .spaceComment {
      .comment {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #81858e;
        word-break: break-word;
        white-space: pre-wrap;
      }
      .actions {
        line-height: 0px;
        .btns {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          border: none;
          background: none;
          margin-top: 30px;
          margin-right: 20px;
          text-transform: uppercase;
        }
        .aprove {
          color: #002363;
        }
        .resp {
          color: #81858e;
        }
        .edit {
          color: #81858e;
        }
        .delete {
          color: #ff0c37;
        }
        .removeaprove {
          color: #dfbe06;
        }
      }
    }
    .spaceCreate {
      .dataCreate {
        display: flex;
        .userDados {
          .name {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            color: var(--fontcolor);
            margin-bottom: 10px;
          }
          .email {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: #81858e;
            margin-bottom: 20px;
          }
        }
        .userPic {
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          margin-right: 20px;
          img {
            width: 100%;
            border-radius: 100%;
          }
        }
      }
      .date {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #81858e;
        margin-left: 60px;
      }
    }
    .table thead th {
      border: none !important;
    }
    .table th,
    .table td {
      border: none !important;
    }
    th {
      div {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        color: var(--fontcolor);
        margin-bottom: 40px;
      }
    }
  }
  .spaceActive {
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--fontcolor);
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }
  .headerFlex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .title {
      
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      color: var(--fontcolor);
    }
    .menu {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--fontcolor);
    }
  }
}

.spaceComments{
  .gridListMenu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-top: 0px;
    float: right;
    padding: 20px;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      float: left;
      gap: 5px;
    }

    .menu {
      cursor: pointer;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      justify-items: start;
      color: var(--fontcolor);
      padding: 7px;
      transition: 0.5s;
      justify-self: center;
    }
  }
  .activeList {
    color: var(--maincolor) !important;
    transition: 0.3s;
    background: #f9f9f92b;
    border-radius: 3px;
    border: 1px solid var(--maincolor);
    svg {
      path {
        stroke: var(--maincolor) !important;
      }
    }
  }
}

.spaceCommentsMobile{
  .gridListMenuMobile {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 25px;
    .menu {
      cursor: pointer;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      justify-items: start;
      color: var(--fontcolor);
      padding: 7px;
      transition: 0.5s;
    }
  }
  .activeList {
    color: var(--maincolor) !important;
    transition: 0.3s;
    background: #f9f9f92b;
    border-radius: 3px;
    border: 1px solid var(--maincolor);
    svg {
      path {
        stroke: var(--maincolor) !important;
      }
    }
  }
}

.spaceComunidade {
  width: 100%;
  padding: 70px var(--spacing-36);
  padding-top: 70px;

  .textTotalSelected {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #81858e;
    margin-bottom: -40px;
    margin-top: 60px;
  }
  .espacamento-svg-action {
    margin-right: 5px;
    margin-bottom: 3px;
  }
  .btns-actions {
    border: none;
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    border-radius: 3px;
    height: 38px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: var(--maincolor);
    margin-right: 20px;
    background-color: #fff0;
    width: 136px;
    margin-top: 30px;
    margin-bottom: -40px;
  }
  .spaceSearch {
    .inputSearch {
      position: relative;
      margin-top: 20px;
      width: 28em;
      top: 10px;
      img {
        cursor: pointer;
      }

      @media screen and (max-width: 600px) {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100%;
      }
    }
    .input-busca {
      width: 100%;
      height: 60px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      border-radius: 30px;
      padding: 20px 30px;
      font-family: Inter;
      font-weight: normal;
      color: #81858e;
      font-size: 14px;
      transition: 0.3s;
    }
    .input-busca:hover,
    .input-busca:focus {
      box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
    }
    .search {
      position: absolute;
      right: 25px;
      top: 20px;
      width: 14.7px;
      height: 14.7px;
      z-index: 999;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
  }
  .paginate {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 60px;
    margin-top: 40px;
    .text {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #333333;
      cursor: pointer;
    }
    .text:hover {
      color: #002363;
    }
    .text:hover svg path {
      stroke: #002363 !important;
    }
  }
  .cancelaBtn {
    margin-right: 20px;
    background: #f7f7f7 !important;
    color: #81858e !important;
  }
  .btnResp {
    background: var(--maincolortrans);
    color: var(--maincolor);
    font-weight: 600;
    font-size: 13px;
    border: none;
    border-radius: 3px;
    padding: 12px 25px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  textarea {
    display: block;
    width: 100%;
    color: var(--fontcolor);
    border: none;
    border-bottom: 0.5px solid #ededf0;
    padding: 5px 0;
    background: var(--backgroundcolor) !important;
    padding-right: 20px;
    font-size: 13px;
    min-height: 50px;
    margin-top: 20px;
  }
  .hide {
    display: none;
  }
  .spaceTable {
    margin-top: 60px;
    .spaceInfo {
      cursor: pointer;
      .info {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #81858e;
        margin-bottom: 5px;
      }
    }
    .spaceComment {
      width: 40em;
      .comment {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #81858e;
        word-break: break-word;
        white-space: pre-wrap;
      }
      .actions {
        display: flex;
        .btns {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          border: none;
          background: none;
          margin-top: 30px;
          margin-right: 20px;
          text-transform: uppercase;
        }
        .aprove {
          color: #002363;
        }
        .resp {
          color: #81858e;
        }
        .edit {
          color: #81858e;
        }
        .delete {
          color: #ff0c37;
        }
        .removeaprove {
          color: #dfbe06;
        }
      }
    }
    .spaceCreate {
      margin-bottom: 40px;
      .dataCreate {
        display: flex;
        .userDados {
          .name {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            color: var(--fontcolor);
            margin-bottom: 10px;
          }
          .email {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: #81858e;
            margin-bottom: 20px;
          }
        }
        .userPic {
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          margin-right: 20px;
          img {
            width: 100%;
            border-radius: 100%;
          }
        }
      }
      .date, .dateResp {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #81858e;
        margin-left: 60px;
      }
      .dateResp {
        margin-left: 0 !important;
      }
    }
    .table thead th {
      border: none !important;
    }
    .table th,
    .table td {
      border: none !important;
    }
    th {
      div {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        color: var(--fontcolor);
        margin-bottom: 40px;
      }
    }
  }
  .spaceActive {
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--fontcolor);
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }
  .headerFlex {
    display: flex;
    width: 100%;
    justify-content: space-between;

    // :v-deep svg path {
    //   fill: var(--opposite-color) !important;
    // }
    .title {
      display: flex;
      gap: 10px;
      align-items: center;
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      color: var(--fontcolor);
    }
    .menu {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--fontcolor);
    }
  }
}
</style>
