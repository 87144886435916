<template>
  <Layout>
    <Comunidade />
  </Layout>
</template>

<script>
import Slidebar from "@/components/Slidebar.vue";
import Comunidade from "@/components/Comunidade.vue";
import Layout from "@/layout/Layout.vue";

export default {
  components: {
    Slidebar,
    Comunidade,
    Layout,
  },

  data() {
    return {
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  mounted() {},
};
</script>
<style  scoped>
.container-grid {
  display: grid;
  grid-template-columns: 100px 1fr;
}

.container-grid2 {
  width: 100%;
}

.slidebarMobile {
  float: right;
  padding-top: 20px;
  position: relative;
  right: 0.6em;
}

.containerx {
  width: 98%;
}
</style>
